<template>
  <!-- 首页 -->
  <Layout class="page-home">
    <div class="main-home">
      <div class="base-width">
        <Search type shopCart v-ani.fade-up />
      </div>
      <!-- 版块1 -->
      <div class="md-home-1">
        <div class="base-width c-layout">
          <!-- 分类 -->
          <div class="col-1">
            <HomeCate :listArr="baseObj.classify" v-ani.fade-up />
          </div>
          <!-- banner、菜单 -->
          <div class="col-2">
            <!-- banner -->
            <Banner :list="bannerList" v-ani.fade-up />
            <!-- 菜单 -->
            <HomeMenu :list="menu" v-ani.fade-up />
          </div>
          <!-- 账号、新闻 -->
          <div class="col-3">
            <HomeUser v-ani.fade-up />
            <!-- 行业资讯 -->
            <HomeNews :listArr="baseObj.industry" v-ani.fade-up />
          </div>
        </div>
      </div>
      <!-- 数字版块 -->
      <HomeData :obj="baseObj.data_statistics" v-ani.fade-up :key="dataKey" />
      <!-- 易筑集采 -->
      <div class="md-home-2">
        <div class="base-width">
          <h2 class="c-title s22" v-ani.fade-up>易筑集采</h2>
          <div class="c-layout">
            <div class="col-1">
              <!-- 分类 -->
              <HomeCate2 :list="baseObj.procurement_cat" link="/shop" v-ani.fade-up />
            </div>
            <div class="col-2">
              <!-- 上半部分 -->
              <HomeMenu2 v-ani.fade-up />
              <!-- 供应商 -->
              <HomeSupplier :listArr="baseObj.cooperation_supplier" v-ani.fade-up />
            </div>
            <div class="col-3">
              <!-- 免费发布需求 -->
              <HomeForm v-ani.fade-up />
            </div>
          </div>
        </div>
      </div>
      <!-- 招投标 -->
      <div class="md-home-3">
        <div class="base-width">
          <h2 class="c-title s22 color-2" v-ani.fade-up>招投标</h2>
          <div class="c-layout">
            <div class="col-1" v-ani.fade-up>
              <HomeCate2 :list="baseObj.bidding_cat" link="/bid" />
            </div>
            <div class="col-2" v-ani.fade-up>
              <!-- 招标项目情况 -->
              <HomeNews2 :data="biddingBase" />
            </div>
            <div class="col-3" v-ani.fade-up>
              <!-- 中标排行榜 -->
              <HomeRank :list="bid_ranking" />
            </div>
          </div>
        </div>
      </div>
      <!-- 智慧工地 -->
      <div class="md-home-4">
        <div class="base-width">
          <h2 class="c-title s22 color-2" v-ani.fade-up>智慧工地</h2>
          <div class="c-layout">
            <div class="col-1" v-ani.fade-up>
              <ImgRatio class="c-card" :src="require('@/assets/images/1.jpg')" :width="328" :height="496" fit='cover' />
            </div>
            <div class="col-2" v-ani.fade-up>
              <!-- 真正实现轻松化工地项目管理 -->
              <HomeMenu3 />
            </div>
            <div class="col-3" v-ani.fade-up>
              <!-- 立即认证工程管理身份 -->
              <HomeMenu4 />
            </div>
          </div>
        </div>
      </div>
      <!-- 配套服务 -->
      <HomeService v-ani.fade-up />
    </div>
  </Layout>
</template>

<script>
import { BiddingBaseApi } from "@/request/api/bid";
import { IndexApi, IndexBannerApi } from "@/request/api/user";
export default {
  name: 'Home',
  created() {
    IndexApi().then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
        this.dataKey++;
      }
    })
    // 轮播
    IndexBannerApi({ from: 417 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      baseObj: {
        classify: [],
        data_statistics: {},
        industry: [],
      },
      // 轮播图列表
      bannerList: [],
      // 轮播图底下的菜单
      menu: [
        { id: 1, img: require('@/assets/images/home-1.jpg'), link: '/finance', target: '', title: '供应链金融', desc: '为工程项目提供材料保等供应链金融服务支持' },
        { id: 2, img: require('@/assets/images/home-2.jpg'), link: '/service', target: '', title: '工程配套服务', desc: '为工程项目提供辅助性支持和保障的综合性服务' },
        { id: 3, img: require('@/assets/images/home-3.jpg'), link: '/bid', target: '', title: '招标/招采', desc: '海量项目，等你来投' },
      ],
      cateList: [
        { id: 1, img: require('@/assets/images/cate-1.png'), title: '土建' },
        { id: 2, img: require('@/assets/images/cate-2.png'), title: '市政' },
        { id: 3, img: require('@/assets/images/cate-3.png'), title: '园林' },
        { id: 4, img: require('@/assets/images/cate-4.png'), title: '暖通' },
        { id: 5, img: require('@/assets/images/cate-5.png'), title: '二手建材' },
        { id: 6, img: require('@/assets/images/cate-5.png'), title: '二手建材' },
        { id: 7, img: require('@/assets/images/cate-5.png'), title: '二手建材' },
      ],
      biddingBase: [],
      bid_ranking: [],
      dataKey: 0,
    }
  },
  computed: {
  },
  mounted() {
    this.getBiddingBase();
  },
  methods: {
    //招标项目情况
    getBiddingBase() {
      BiddingBaseApi().then(res => {
        console.log(res);
        if (res.status == 200) {
          console.log(132465);
          this.biddingBase = res.data.project_situation;
          res.data.bid_ranking.map(item => {
            let obj = {
              title: item
            }
            this.bid_ranking.push(obj);
          });
          this.biddingBase = this.biddingBase.map(item => {
            item.article = item.list;
            delete item.list;
            return item;
          })
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.md-home-1,
.md-home-2,
.md-home-3,
.md-home-4 {
  margin: .2rem 0;
}
</style>
