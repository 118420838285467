// 招投标
import request from '@/request/request';

//招标项目情况
export const BiddingBaseApi = (params) => request.post('/pc/bidding.BigBidding/base', params);

//最新招投标
export const BiddingListApi = (params) => request.post('/pc/bidding.BigBidding/bidding_list', params);

//消息列表
export const BiddingMsgListApi = (params) => request.post('/pc/bidding.BigBidding/msg_list', params);

//分类说明
export const BiddingCatListApi = (params) => request.post('/pc/bidding.BigBidding/cat_list', params);

//招采大厅
//招标项目情况
export const biddingBaseApi = (params) => request.post('/pc/bidding.bidding/base', params);

//最新招投标
export const biddingListApi = (params) => request.post('/pc/bidding.bidding/bidding_list', params);

//消息列表
export const biddingMsgListApi = (params) => request.post('/pc/bidding.bidding/msg_list', params);

//分类说明
export const biddingCatListApi = (params) => request.post('/pc/bidding.bidding/cat_list', params);

//地区
export const biddingAreaListApi = (params) => request.post('/pc/bidding.bidding/area_list', params);

//状态
export const biddingStateListApi = (params) => request.post('/pc/bidding.bidding/state_list', params);
